// 3rd-party Plugins
@import '~highlight.js/styles/googlecode.css';
@import '~perfect-scrollbar/css/perfect-scrollbar.css';

// Theme Styles
@import './_metronic/_assets/sass/style.react.scss';

// Default Layout themes
@import './_metronic/_assets/sass/themes/layout/header/base/light.scss';
@import './_metronic/_assets/sass/themes/layout/header/menu/light.scss';
@import './_metronic/_assets/sass/themes/layout/brand/dark.scss';
@import './_metronic/_assets/sass/themes/layout/aside/dark.scss';

// Header themes
// Light
@import './_metronic/_assets/sass/themes/layout/header/base/light.scss';
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/base/dark.scss";

// Header Menu themes
// Light
@import './_metronic/_assets/sass/themes/layout/header/menu/light.scss';
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/menu/dark.scss";

// Brand themes
.brand-dark {
  @import './_metronic/_assets/sass/themes/layout/brand/dark.scss';
}
// Light
.brand-light {
  @import './_metronic/_assets/sass/themes/layout/brand/light.scss';
}

// Aside themes
// Dark
//@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
// Light
@import './_metronic/_assets/sass/themes/layout/aside/light.scss';

// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }

.white-bg {
  background-color: white;
}

.example-highlight > pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem;
  code {
    overflow-y: auto;
    display: block;

    span {
      background: none transparent !important;
    }
  }
}

.json > pre {
  background: none #fff !important;
}

.example-code > .json {
  background: none #fff !important;
}

.symbol.symbol-45 > svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.react-bootstrap-table {
  overflow-x: auto;
}

.react-bootstrap-table {
  th {
    outline: none;

    &.sortable {
      .svg-icon-sort {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }
  }

  .table.table-head-custom thead tr {
    .sortable-active {
      color: $primary !important;
    }
  }
}

.cursor-default {
  cursor: default !important;
}

.display-block {
  display: block;
}

/* Nadpisywanie stylów Material UI */

.MuiButtonBase-root {
  font-size: 1rem !important;
}

.MuiButton-contained {
  color: white !important;
  background-color: rgb(54, 153, 255) !important;
}

.MuiStepLabel-completed,
.MuiStepLabel-active,
.MuiStep-completed,
.MuiStep-completed .MuiStepLabel-root .MuiStepLabel-iconContainer i.wizard-icon,
.MuiStep-completed + .MuiStep-root i {
  color: rgb(54, 153, 255) !important;
  transition: 0.2s ease-in-out color, 0.25s opacity;
}

.MuiPaper-root span.MuiStepLabel-root:hover {
  cursor: pointer !important;
  opacity: 0.7;
  transition: 0.2s opacity;
}

.MuiStepConnector-line {
  transition: 0.2 ease-in background-color;
}

.MuiStepLabel-label {
  font-size: 1.1rem !important;
}

#kt_content {
  padding: 25px 0px !important;
  overflow-x: hidden !important;
}

@media only screen and (max-width: 600px) {
  .MuiStepLabel-label {
    font-weight: 500 !important;
  }

  .MuiStepper-horizontal {
    flex-direction: column !important;
  }

  .MuiStep-horizontal > span.MuiStepLabel-horizontal {
    flex-direction: row !important;
  }

  .MuiStepConnector-line {
    display: none !important;
  }

  .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
    margin: 0px !important;
    margin-left: 1px !important;
  }

  .wizard-icon {
    font-size: 1.5rem !important;
  }
}

.invalid-input-label {
  color: rgb(246, 78, 96);
}

.invalid-input {
  border: 1px solid rgb(246, 78, 96) !important;
}

/* */

input:-webkit-autofill {
  box-shadow: inset 0 0 0px 9999px white;
  -webkit-box-shadow: inset 0 0 0px 9999px white;
}

input {
  filter: none;
}

.pagination.react-bootstrap-table-page-btns-ul li > a {
  font-size: 0.95rem;
  font-weight: 500;
  color: #7e8299;
  border: 0;
  border-radius: 5.7px;
}

.pagination.react-bootstrap-table-page-btns-ul li {
  margin-right: 5.7px;
}

.pagination.react-bootstrap-table-page-btns-ul {
  margin: 0;
}

.pagination.react-bootstrap-table-page-btns-ul li[title='first page'] > a,
.pagination.react-bootstrap-table-page-btns-ul li[title='previous page'] > a,
.pagination.react-bootstrap-table-page-btns-ul li[title='next page'] > a,
.pagination.react-bootstrap-table-page-btns-ul li[title='last page'] > a {
  color: rgb(181, 181, 195);
  background-color: rgb(236, 241, 246);
}

.pagination.react-bootstrap-table-page-btns-ul li:hover > a {
  color: white !important;
  opacity: 0.95;
  background-color: var(--primary);
}

#pageDropDown {
  border: 0;
  color: rgb(126, 130, 153);
  background-color: #ecf1f6 !important;
  padding: 0.45rem 1rem;
  font-weight: 500;
}

#pageDropDown:hover {
  background-color: var(--primary) !important;
  color: white;
}

#pageDropDown::after {
  color: inherit;
}
.custom-table-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.5em;
}

.custom-tab-right-pagination {
  padding-right: 9.75px;
}

.pagination-standalone-list {
  padding-left: 9.75px;
}

.custom-tab-right-pagination > * {
  margin: 0 0.4em;
}

.custom-tab-right-pagination .dropdown-menu a {
  color: black !important;
}

.menu-arrow::before {
  transform: none !important;
}

.sticky-toolbar {
  display: none;
}

.disabled-link {
  pointer-events: none;
  opacity: 20%;
}

#select-filter-column-active,
#select-filter-column-statuses,
#select-filter-column-role {
  display: none;
}

.pagination.react-bootstrap-table-page-btns-ul li.active > a {
  color: white !important;
}

.light-btn-custom {
  background-color: rgb(236, 241, 246);
}

.pagination li {
  width: 29px !important;
  height: 29px !important;
}

.pagination li a {
  font-size: 11.7px !important;
}

#kt_wrapper {
  padding-top: 65px;
}

// Loaderek
.loading:after {
  display: block;
  content: '';
  border: 10px solid #f3f3f3;
  border-radius: 50%;
  border-top: 10px solid #3498db;
  width: 20px;
  height: 20px;
  -webkit-animation: spin 0.55s linear infinite; /* Safari */
  animation: spin 0.55s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.table_loader_content {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  color: black;
  box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
  border-radius: 0.42em;
  cursor: wait;
  font-size: 13px;
  font-weight: 400;
  font-family: Poppins, Helvetica, 'sans-serif';
  line-height: 1.5;
  width: 160px;
  padding: 16px 10px;
  padding-left: 0;
}

.spinner::before {
  left: unset;
}

.table_loader_content .spinner {
  position: unset;
}

.table_loader_spinner {
  width: 22px;
  margin: 0;
  margin-left: 16px;
  color: var(--primary);
}

.table_loader_spinner * {
  color: inherit;
  stroke: var(--primary) !important;
}

.custom_overlay {
  position: absolute;
  left: 45%;
  top: 50%;
}

.MuiStepper-root {
  padding: 43.5px 67px !important;
  border-bottom: 1px solid #ebedf3 !important;
}

.MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiInputBase-input.MuiInput-input {
  background: white !important;
  border: 1px solid !important;
  border-radius: 5px !important;
  font-size: 12px !important;
  font-family: Poppins, Helvetica, sans-serif !important;
  font-weight: 400 !important;
}

.MuiOutlinedInput-notchedOutline {
  border-color: rgb(228, 230, 239) !important;
}

.MuiInputBase-root.MuiOutlinedInput-root {
  height: 38.4px !important;
  width: 100% !important;
}

.MuiSelect-outlined.MuiSelect-outlined {
  background: white !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: var(--primary) !important;
  border-width: 1px !important;
}

.dropdown-menu.show[x-placement='bottom_start'] {
  width: 100%;
}

.custom-select-lazy {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14.04px;
  padding: 0;
  padding-right: 18.46px;
}

.custom-search-input {
  background-color: inherit;
  border: 0 !important;
  width: 100%;
  padding: 10px 18.46px;
  border-radius: 5.46px;
}

.custom-search-input:focus {
  background-color: inherit;
  border: 0 !important;
  outline: none;
}

.custom-search-input:hover {
  border: 0 !important;
}

.timeline-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
  border-bottom-width: 70% !important;
  border-radius: 0px;
  border-bottom-style: dashed !important;
}

.timeline-item:last-child {
  border-bottom: 0 !important;
}

.statuses
  .timeline.timeline-5
  .timeline-items
  .timeline-item
  .timeline-desc::before {
  top: 35px !important;
}

/*
.spinner.spinner-white::before {
  border: 2px solid var(--primary);
  border-right: 2px solid transparent;
}
*/

.custom-search-input::placeholder {
  color: black;
  opacity: 0.77;
}

.custom-search-input::-webkit-input-placeholder {
  /* Edge */
  color: black;
  opacity: 0.77;
}

.custom-search-input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: black;
  opacity: 0.77;
}

.custom-search-input::placeholder {
  color: black;
  opacity: 0.77;
}

.custom-disabled {
  opacity: 0.4;
  pointer-events: none;
}

.spinner-xxl::before {
  width: 4.25rem;
  height: 4.25rem;
}

.page-custom-loader .spinner-lg::before {
  width: 3rem !important;
  height: 3rem !important;
}

.custom-datepicker-round {
  opacity: 0.01;
  width: 0;
}

.custom-absolute-icon {
  position: absolute;
  align-items: center;
  left: 19%;
}

.custom-summary > * {
  margin: 5px 0;
}

.status-select {
  .btn.btn-primary.dropdown-toggle::after {
    display: none;
  }

  .dropdown-menu.show {
    padding: 0 !important;
  }
}

.custom-modal .modal-footer {
  display: none;
}

#progress-box {
  position: absolute;
  display: none;
  left: 80%;
  bottom: 100px;
  width: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  background-color: white;
  color: black;
  box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
  border-radius: 0.42em;
  cursor: wait;
  font-size: 13px;
  font-weight: 400;
  font-family: Poppins, Helvetica, 'sans-serif';
  line-height: 1.5;
  padding: 16px 10px;
  padding-left: 0;
  opacity: 0;
  transition: 0.25s opacity, 0.4s color;
  pointer-events: none;
}

#kt_footer {
  display: none !important;
}

#kt_header {
  box-shadow: 0px 10px 30px 0px rgba(82, 63, 105, 0.08) !important;
}

.tooltip {
  margin-bottom: 8px !important;
}

.marek-style {
  border-radius: 10px !important;
  font-weight: 300 !important;
  line-height: 24px !important;
  white-space: nowrap;
}

.icon-size-13 {
  font-size: 13px !important;
}

.font-size-11 {
  font-size: 11px;
}

.datepicker-range {
  display: flex;
  flex-flow: row;
}

.statuses-dropdown {
  border-top: 1px solid #e3e3e3;
}

.statuses-dropdown:first-child {
  border-top: 0;
}

.status-select {
  .statuses-dropdown:first-child {
    border-radius: 10px 10px 0 0;
  }

  .statuses-dropdown:last-child {
    border-radius: 0 0 10px 10px;
  }

  .dropdown-menu.show {
    border-radius: 10px;
  }
}

.react-datepicker__triangle {
  display: none;
}

.custom-timeline.timeline.timeline-6 {
  &::before {
    left: 91px;
  }

  .timeline-label {
    width: 90px;
  }
}

.custom-timeline.timeline.timeline-6:last-child::before {
  display: none;
}

.icon-color {
  color: #b6b6c4;
}

#kt_quick_panel {
  display: none;
}

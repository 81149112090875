//
// Customdatepicker
//

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker {
  box-shadow: rgba(82, 63, 105, 0.15) 0px 0px 50px 0px;
  border: 0 !important;

  .custom-datepicker-round .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__day {
    font-family: Poppins, Helvetica, 'sans-serif';
    font-size: 13px;
    width: 35px;
    height: 35px;

    span {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      font-weight: 400 !important;
    }
  }

  .react-datepicker__day-name {
    color: #3f4254;
    width: 35px;
    font-weight: 600 !important;
  }

  .react-datepicker__day--selected {
    font-weight: normal !important;
    background: #e1f0ff;
    color: #3699ff;
  }

  .react-datepicker__header {
    font-family: Poppins, Helvetica, 'sans-serif';
    font-size: 13px;
    background: white;
    border: 0;
  }

  .react-datepicker__current-month {
    color: #3f4254;
    font-weight: 500 !important;
    font-size: 13px;
  }

  .react-datepicker__day--today {
    background: #e1f0ff !important;
    color: #3699ff !important;
    font-weight: 400 !important;
  }

  .react-datepicker__day--today span::before {
    content: '';
    display: inline-block;
    border: solid transparent;
    border-top-color: transparent;
    border-top-width: medium;
    border-right-width: medium;
    border-bottom-color: transparent;
    border-bottom-width: medium;
    border-left-width: medium;
    border-width: 0 0 7px 7px;
    border-bottom-color: #3699ff;
    border-top-color: #3699ff;
    position: absolute;
    bottom: 4px;
    right: 4px;
  }

  .react-datepicker__day--selected {
    background: #3699ff;
    color: #ffffff;
  }

  .react-datepicker__month {
    margin-top: 0;
  }

  .react-datepicker__day-names {
    margin-top: 12px;
  }

  .day-current-month {
    color: #7e8299;
  }
}

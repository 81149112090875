//
// Customdropzone
//

.dropzone {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 264px;
  border: 2px dashed rgb(173, 173, 173);
  border-radius: 7px;
  font-weight: 500;
  color: #3f4254;
}

//
// Editor
//

$icon-color: #95c952;
$hover-icon-color: #adeb5d;
$inactive-icon: #d9d9d9;
$text-color: #5b5b5b;

.format-panel {
  display: flex;
  flex-flow: row;
  margin-right: auto;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
}

path {
  transition: 0.25s fill;
}

.t-accepted {
  path {
    fill: $icon-color !important;
  }
}

.lazy-fragment {
  border: 1px solid rgb(204, 204, 204);
  border-right: 0;
  border-left: 3px solid rgb(216, 216, 216);
  transition: 0.15s border-left;

  &:focus-within {
    border-left: 3px solid $icon-color !important;
    background: rgba(58, 0, 216, 0.025);

    textarea {
      font-weight: 500 !important;
      color: rgba(0, 0, 0, 0.9) !important;
    }
  }

  .lazy-left-col {
    display: flex;
    flex-flow: row;
    width: 30%;
    justify-content: space-between;
  }

  .block-info {
    display: flex;
    flex-flow: row;
    height: 100%;
    padding: 0 1em;
  }

  .info-column {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    margin: 1em 0;
  }

  .info-row {
    display: flex;
    color: #5b5b5b;
    flex-flow: row;
  }

  .column-alignment {
    display: none !important;
  }

  .dropdown-util-menu {
    display: flex;
    flex-flow: column;
  }

  .textarea-container {
    width: 100%;
    height: 100%;
    padding-top: 1rem;
  }

  .lazy-textarea {
    background: rgba(255, 255, 255, 0);
    color: #5b5b5b;
    border: 0;
    resize: none;
    text-align: center;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    width: 100%;
    height: 100%;

    background-image: linear-gradient(
      transparent,
      transparent calc(4ch - 1px),
      #e7eff880 0px
    );
    background-size: 100% 4ch;
    line-height: 4ch;
    font-size: 11px;
    padding: 0;
    font-size: 12px;
  }
}

.arrow-icon {
  width: 18px;
  height: 18px;
}

.play-icon {
  width: auto;
  height: 20px;
}

.index-num {
  font-size: 25px;
  color: #d9d9d9;
}

.index-info {
  min-width: 45px;
}

.lazy-cpl {
  display: flex;
  flex-flow: column;
  align-items: center;
  background: #e5e5e5;
  color: #5b5b5b;
  font-size: 12px;
  margin: 0.75em 1.15em;
  padding: 0px 15px;
  border-radius: 6px;
  overflow: hidden;
}

.lazy-cps {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  background: #e5e5e5;
  color: #5b5b5b;
  border-radius: 6px;
  padding: 5px 1em;
  min-width: 60px;
  margin-left: 10px;
}

.action-icon {
  width: 24px;
  height: auto;

  path {
    fill: $inactive-icon;
  }
}

.lazy-border-icons {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: calc(100% - 50px);
  top: calc(100% - 12.5px);
  width: 50px;
  height: 25px;
  border-radius: 6px;
  background: $inactive-icon;
  z-index: 10;

  svg {
    cursor: pointer;
    width: 12px;
    height: auto;
  }

  svg:hover {
    path {
      fill: rgb(161, 161, 161);
    }
  }
}

.lazy-icons {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: flex-end;
  width: 10%;

  div {
    cursor: pointer;
    margin: 0.35em 0;
  }

  div:first-child:hover {
    svg {
      path {
        fill: $danger;
      }
    }
  }

  div:last-child:hover {
    svg {
      path {
        fill: $hover-icon-color;
      }
    }
  }
}

.lazy-fragment + .lazy-fragment {
  border-top: 0;
}

.editor {
  background: white;
  height: 100vh;

  video {
    border-radius: 10px;
    max-width: 800px;
  }

  input[type='range'] {
    -webkit-appearance: none;
    background: rgba(0, 0, 0, 0);
    margin-bottom: 1.3rem;
  }

  input[type='range']::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    height: 15px;
    background: #bee78a;
    border: 0;
    transition: 0.2s all;
    border-radius: 10px;
  }

  input[type='range']:hover::-webkit-slider-runnable-track {
    cursor: pointer;
    background: #d7ebbe;
  }

  input[type='range']::-webkit-progress-bar {
    -webkit-appearance: none;
    background: red;
  }

  input[type='range']::-webkit-slider-thumb {
    border: 0;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    background: #95c952;
    -webkit-appearance: none;
  }

  input[type='range']::-moz-range-thumb {
    background: #95c952;
    border: 0;
    border-radius: 50%;
    cursor: pointer;
    width: 16px;
    height: 16px;
  }

  input[type='range']::-moz-range-track {
    height: 8px;
    cursor: pointer;
    border-radius: 10px !important;
    border: 0 !important;
    background: #eeeeee !important;
  }

  input[type='range']::-moz-range-progress {
    height: 8px;
    cursor: pointer;
    border-radius: 10px !important;
    border: 0 !important;
    background: #bee78a !important;
  }
}

.editor-header {
  display: flex;
  flex-flow: row;
  align-items: center;
  height: 10vh;
  box-shadow: 0px 30px 50px rgba(0, 0, 0, 0.1);

  @media (max-height: 800px) {
    padding: 10px 0;
  }

  .editor-logo {
    width: 50%;
    padding: 3.5rem;

    svg {
      width: 120px;
      height: 100%;
    }
  }

  .editor-options {
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
    align-items: center;
    padding: 3.5rem;
    width: 50%;

    .editor-options-ctrls {
      display: flex;
      margin: 0 2rem;
    }
  }
}

.editor-container {
  display: flex;
  flex-flow: row-reverse;
  padding: 1em;
  height: 70vh;

  .editor-timestamps {
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-color: #95c952 #eee;
    scrollbar-width: thin;
    width: 50%;
    min-width: 700px;

    &-data-holder {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      font-size: 11px;
      min-width: 140px;
    }

    textarea::-webkit-scrollbar {
      width: 5px;
    }

    textarea::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    textarea::-webkit-scrollbar-thumb {
      background-color: #95c952;
      outline: 1px solid #95c952;
      border-radius: 8px;
    }
  }

  .editor-timestamps::-webkit-scrollbar-thumb {
    background-color: #95c952;
    border-radius: 8px;
  }

  .editor-timestamps::-webkit-scrollbar {
    height: 52px;
    width: 6px;
    background: white;
  }

  .editor-video {
    width: 50%;
    overflow-y: auto;

    &-actions {
      .icons-container {
        display: flex;
        flex-direction: row;
        font-size: 10px;
        gap: 6px;
        color: $icon-color;

        div {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          transition: 0.2s all;

          &:hover {
            color: $hover-icon-color;

            i {
              color: $hover-icon-color;
            }
          }

          i {
            color: $icon-color;
            transition: 0.2s all;
          }
        }
      }
    }
  }

  .timestamp-block:not(:first-child) {
    border-top: 0;
  }

  .timestamp-block {
    display: flex;
    flex-flow: row;
    min-height: 145px;
    // max-height: 17%;
    border: 1px solid #d9d9d99d;
    border-right: 0 !important;
    padding: 0.5rem;
    margin: 0 1rem;
    position: relative;

    @media (max-width: 1600px) {
      max-height: 30%;
    }

    &.active {
      border: 1px solid #95c952;
    }

    &-icons-border {
      position: absolute;
      right: 8px;
      bottom: -8px;
      z-index: 5;
      display: flex;
      flex-direction: row !important;
      padding: 0 !important;
      justify-content: center;
      align-items: center;
      flex-grow: 1;
      width: 80px;
    }

    &-add {
      z-index: 6;
    }

    &-duplicate {
      z-index: 6;
    }

    &-info {
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      width: 25%;
      height: 100%;
      padding: 1rem;
    }

    &-content {
      display: flex;
      flex-flow: row;
      color: #5b5b5b;
      font-size: 115%;
      justify-content: center;
      align-items: center;
      width: 70%;

      .content-text {
        max-height: 100%;
        width: 80%;
        height: 100%;
        text-align: center;
        outline: none;
        background-color: inherit;
        resize: none;
        border-width: 0;
      }
    }

    &-icons {
      padding: 2rem 2px;
      width: 5%;
      transition: 0.08s opacity;

      i {
        margin: 0.6rem;
        color: #c9c9c9;

        &.bookmarked {
          color: #95c952;
        }
      }
    }

    &:hover {
      .timestamp-block-icons {
        opacity: 1;
      }

      .textblock-border-icons {
        opacity: 1;
      }
    }
  }
}

.timestamp-block-icons {
  display: flex;
  flex-flow: column;
  margin: 0 !important;
  justify-content: flex-start;
  align-items: flex-end;
  padding-top: 15px !important;

  & div:first-child {
    margin-bottom: 19px;
  }

  & svg {
    cursor: pointer;
    width: 24px !important;
    height: auto;

    &.corrector-acc-block {
      path {
        opacity: 1;

        transition: 0.12s fill, 0.12s opacity;
      }
    }

    &.corrector-acc-block:hover {
      path {
        fill: rgb(149, 201, 82);
      }
    }

    &.delete-block {
      path {
        opacity: 1;
        transition: 0.2s all;
      }
    }

    &.delete-block:hover {
      path {
        fill: var(--danger);
      }
    }

    path {
      fill: #c7c7c7;
      opacity: 1;
      transition: 0.2s all;
    }
  }

  & svg.accepted-transcript {
    path {
      fill: #95c952;
    }
  }
}

.editor-audio {
  height: 20vh;
  scroll-behavior: smooth;
}

.cps-and-times {
  width: 80%;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  margin-right: 7px;

  i {
    color: #c9c9c9;
  }

  .cps {
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    min-width: 35px;
    padding: 6px;
    background: #e5e5e5;
    color: #5b5b5b;
    border-radius: 4px;

    @media (max-width: 1367px) {
      font-size: 10px !important;
    }
  }

  .cps-block {
    display: flex;
    flex-flow: row;
    justify-content: space-evenly;
    align-items: center;

    i {
      color: #95c952;
    }
  }
}

.cpl {
  display: flex;
  flex-flow: column;
  align-items: center;
  background: #e5e5e5;
  width: 20%;
  color: #5b5b5b;
  border-radius: 4px;
  font-size: 12px;

  .cpl-text {
    margin: 8px;

    @media (max-height: 800px) {
      margin: 2px;
    }
  }
}

.danger,
.danger {
  background: #f45f5f !important;
  color: white !important;
}

.danger-cps {
  background: red !important;
  color: white !important;
}

.timestamp-number {
  font-size: 22px;
  line-height: 27px;
  color: #c9c9c9;
}

.times-block {
  .times-block-item {
    display: flex;
    color: #5b5b5b;
    flex-flow: row;
    align-items: center;
    justify-content: space-evenly;
  }
}

.editor-container-block {
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 0.75rem;
  padding-top: 0;
  padding-bottom: 0;

  .control-input-video {
    margin-top: 4.5px;
    width: 100%;
  }

  .MuiSlider-rail {
    height: 8px !important;
    border-radius: 10px !important;
  }

  .MuiSlider-track {
    height: 8px !important;
    border-radius: 10px !important;
  }

  .MuiSlider-colorPrimary {
    color: $icon-color !important;
  }

  .MuiSlider-thumb {
    width: 18px !important;
    height: 18px !important;
    color: #74ae2b !important;
  }
}

.player-controls-custom {
  width: 100%;
  height: 100%;
}

.control-speed-time-indicator {
  display: flex;
  flex-flow: column;
  font-size: 130%;
  margin-left: 3rem;
}

.control-buttons {
  display: flex;
  flex-flow: row;
  font-size: 11px;
}

.undo-redo-controls {
  display: flex;
  margin-left: auto;

  button {
    display: flex;
    flex-flow: column;
    color: #95c952;
    font-weight: 300;
    margin-left: 25px;
  }
}

.box-selection {
  display: inline-flex;
  flex-flow: column;
}

.player-wrapper {
  position: relative;
  // padding-top: 46.25%; /* 720 / 1280 = 0.5625 */
  // width: 82%;

  // padding-top: 42%;
  // width: 75%;

  video {
    height: auto !important;
  }
}

.waveform-img {
  position: absolute;
  bottom: 10px;
  pointer-events: none;

  @media (max-height: 875px) {
    top: 50px;
  }

  @media (max-height: 800px) {
    top: 30px;
  }

  @media (max-height: 750px) {
    top: 15px;
  }
}

.player-subtitles {
  width: 100%;
  position: absolute;
  display: flex !important;
  margin-bottom: 6px;
}

.react-player {
  // position: absolute;
  // top: 0;
  // left: 0;
  width: 100% !important;
  height: 100% !important;
}

.ctrl-btn-trans {
  border: 0;
  background: rgba(255, 255, 255, 0);
  opacity: 1;
  transition: 0.2s all;
  cursor: pointer;

  label {
    cursor: pointer;
  }

  path {
    fill: $icon-color;
    transition: 0.2s all;
  }

  &:hover {
    path {
      fill: $hover-icon-color;
    }

    color: $hover-icon-color;
  }

  svg {
    width: 28px;
    height: 100%;
  }
}

.ctrl-btn-playstop {
  border-radius: 50%;
  background: $icon-color;
  border: 0;
  width: 60px;
  height: 60px;
  transition: 0.2s all;

  &:hover {
    background: $hover-icon-color;
  }

  @media (max-height: 800px) {
    width: 50px;
    height: 50px;

    svg {
      width: 30px;
    }
  }

  path {
    fill: white !important;
  }

  svg {
    width: 40px;
    height: 100%;
  }
}

.playstop-ctrls {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 6px;

  button {
    margin-right: 1.35rem;
  }
}

.menu-utils span {
  width: 100%;
  margin: 0 !important;
  padding: 4px 7px;
  transition: 0.1s background, 0.1s opacity;
  opacity: 0.9;

  &:hover {
    cursor: default;
    background: rgb(233, 233, 233);
    opacity: 1;
  }
}

.green-border-input {
  display: flex;
  flex-flow: row;
  align-items: center;
  cursor: default;
  color: #95c952;
  border: 0.6px solid #95c952;
  border-radius: 3px;
  background: white;
  height: 28px;
  padding: 3px 5px;
  transition: 0.16s background;

  &:hover {
    background: rgb(238, 238, 238);
  }

  & + .dropdown-menu {
    min-width: auto !important;
  }
}

.gray-border-input {
  display: flex;
  flex-flow: row;
  align-items: center;
  cursor: default;
  color: #585858;
  border: 0.6px solid #d9d9d9;
  border-radius: 3px;
  background: white;
  height: 28px;
  padding: 3px 5px;
  transition: 0.16s background;

  &:hover {
    background: rgb(238, 238, 238);
  }

  & + .dropdown-menu {
    min-width: auto !important;
  }

  path {
    fill: #585858;
  }
}

.format-icon {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  width: 24px;
}

.format-text-color {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  .color {
    width: 11px;
    height: 2px;
    margin-top: 1.5px;
  }
}

.bottom-editor {
  display: flex;
  flex-flow: row;
  position: relative;
  background: #f3f3f3;
  height: 20vh;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-color: #95c952 white;
  scrollbar-width: thin;
}

.bottom-editor::-webkit-scrollbar-thumb {
  background-color: #95c952;
  border-radius: 10px;
}

.bottom-editor::-webkit-scrollbar {
  height: 8px;
  width: 52px;
  background: white;
}

.rnd-text-block {
  border: 0.6px solid #95c952;
  background: rgba(255, 255, 255, 0.45);
  border-radius: 4px;
  margin-top: 15px !important;
  text-align: center;
  color: rgba(91, 91, 91, 0.82);
  height: 60px !important;
  overflow: hidden;
  cursor: grab !important;
  -moz-box-shadow: inset 0 0 15px rgb(248, 248, 248);
  -webkit-box-shadow: inset 0 0 15px rgb(248, 248, 248);
  box-shadow: inset 0 0 15px rgb(248, 248, 248);
  z-index: 7;
  transition: 0.25s background-color;

  .rnd-text-block-inner {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 10px !important;
  }

  .rnd-handle:last-child::after {
    left: 3px !important;
  }

  .rnd-handle {
    opacity: 0;
    transition: 0.05s opacity, 0.1s color;
    cursor: ew-resize !important;

    &::after {
      position: absolute;
      content: '';
      height: 19px;
      top: 32%;
      width: 5px;
      left: 7px;
      border-right: 1px solid white;
      border-left: 1px solid white;
    }
  }

  &:hover,
  &:active,
  &:focus {
    color: rgba(91, 91, 91, 1);
    background: rgba(255, 255, 255, 0.9);

    .rnd-handle {
      opacity: 1;
    }
  }
}

.no-user-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.timeline-editor {
  position: absolute;
  bottom: 0;
  display: flex;
  $second_length: 100px;

  &::after {
    background: white;
    width: 100%;
    height: 100px;
    content: '';
    position: absolute;
    z-index: 10;
    overflow: hidden;
    top: -20px;
    z-index: 5;
    cursor: pointer;
  }

  &-item {
    display: flex;
    justify-content: center;
    position: relative;
    align-items: center;
    width: $second_length;
    font-size: 11px;
    color: #5c5c5c;
    cursor: pointer;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    z-index: 6;
  }

  &-item::after {
    position: absolute;
    left: 50%;
    top: -125%;
    background: #5f5f5f;
    content: '';
    width: 2px;
    height: 16px;
    cursor: pointer;
  }

  &-item::before {
    position: absolute;
    left: 0;
    top: -125%;
    background: #5f5f5f;
    content: '';
    width: 2px;
    height: 10px;
    cursor: pointer;
  }

  &-item:first-child {
    margin-left: 50px;
  }
}

.btm-pointer {
  position: sticky;
  top: 0;
  height: 85%;
  width: 3px;
  background: #95c952;
  content: '';
  z-index: 6;
  transition: 0.2s left;
}

.btm-pointer::after {
  position: absolute;
  width: 10px !important;
  height: 10px !important;
  background: none !important;
  content: '';
  top: 96%;
  transform: rotate(45deg);
  left: -3.5px;
  border-bottom: 2px solid rgba(149, 201, 82, 1);
  border-right: 2px solid rgba(149, 201, 82, 1);
}

.textblock-border-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 93%;
  left: 95%;
  background: #d9d9d9;
  z-index: 100;
  opacity: 1;
  padding: 5px 3px;
  color: white !important;
  border-radius: 8px;
  transition: 0.2s all;

  & svg {
    cursor: pointer;
    margin: 0 3.5px;
    width: 16px;
    height: 16px;
    transition: 0.2s all;

    &:hover {
      opacity: 0.65;
    }
  }
}

.editor-save-icon {
  &:hover {
    path {
      fill: $hover-icon-color;
    }
  }

  path {
    fill: $icon-color;
    transition: 0.2s all;
  }
}

.editor-video-actions {
  display: flex;
  flex-flow: row;
  padding: 0.75rem;

  .accept-all {
    display: flex !important;
    flex-flow: column !important;
    justify-content: center;
    align-items: center;
    color: $icon-color;
    cursor: pointer;
    transition: 0.2s all;

    &:hover {
      color: $hover-icon-color;

      i {
        color: $hover-icon-color;
      }
    }

    i {
      color: $icon-color;
      transition: 0.2s all;
    }
  }
}

.words-container {
  border: 1px solid #f3f6f9;
  margin-top: 1.35rem;
  height: 240px;
  border-radius: 10px;
  overflow: auto;
  background: #e3e1ee80;
}

.words-item {
  display: flex;
  color: $text-color;
  align-items: center;
  padding: 5px 15px;
  min-height: 45px;
  word-break: break-word;
  text-align: justify;
  justify-content: space-between;

  i {
    cursor: pointer;

    &:hover {
      color: rgb(149, 201, 82);
    }
  }

  .holder {
    width: 40%;
  }
}

.words-item:nth-child(even) {
  background: #eeeef3;
}

.timestamps-icons {
  display: flex;
  justify-content: space-between;
  width: 12.5%;
}

.a {
  border: 1px solid black;
  height: 300px;
}

.left-scrollable {
  background: white;
  height: 100%;
  overflow-y: scroll;
}

.left-inside-scrollable {
  position: relative;

  textarea {
    overflow: hidden !important;
    background: rgba(255, 255, 255, 0);
    color: #5b5b5b;
    border: 0;
    resize: none;
    text-align: center;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    width: 100%;
    overflow: hidden;
  }
}

.left-block {
  border: 1px solid rgb(202, 202, 202);
  border-top: none;
  height: 180px;

  textarea {
    height: 100%;
    margin: 1em 1.35em;
    padding-top: 19.5px;
    font-size: 12px;
  }

  &:first-child {
    border-top: 1px solid rgb(202, 202, 202);
  }
}

.active-scroll-element {
  textarea {
    color: rgb(109, 192, 0) !important;
  }
}

.editor-video-responsive {
  display: none;
}

@media (max-width: 1140px) {
  body {
    overflow: auto !important;
  }

  .editor {
    &-container {
      flex-flow: column-reverse;
      gap: 20px;
      height: auto;
    }

    &-video {
      width: 100% !important;
    }

    &-timestamps {
      height: 700px;
      width: 100% !important;
    }
  }

  .player-subtitles div {
    font-size: 20px !important;
  }
}
